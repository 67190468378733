import { CDN_URL } from "@env";
import { MainTypes } from "./image-types/main-types";
import LandscapeAssets from "./landscape-assets";

const images: MainTypes = {
  "bg-splash": CDN_URL + "/image-assets/signing-option/bg-splash.webp",
  "elements": CDN_URL + "/image-assets/signing-option/elements.webp",
  "referee": CDN_URL + "/image-assets/signing-option/referee.png",
  "bg-progress": CDN_URL + "/image-assets/signing-option/bg_progress.png",
  "phone_rotate": CDN_URL + "/image-assets/signing-option/phone_rotate.png",

  // SIGNING OPTIONS
  "gamezone-bg": CDN_URL + "/image-assets/signing-option/home_bg.webp",
  "gamezone-logo": CDN_URL + "/image-assets/signing-option/gamezone-logo.png",
  fb_register:  CDN_URL + "/image-assets/signing-option/fb_register.png",
  main_login_button: CDN_URL + "/image-assets/signing-option/main_login_button.png",
  mainRegisterEmailButton: CDN_URL + "/image-assets/signing-option/main_register_button.png",
  mainRegisterGoogleButton: CDN_URL + "/image-assets/signing-option/main_register_google_button.png",  
  "gamezone-logo-horizontal": CDN_URL + "/image-assets/signing-option/gamezone-logo-horizontal.png",
  bg_transparent: CDN_URL + "/image-assets/signing-option/bg_transparent.webp",
  "bonus-gold2": CDN_URL + "/image-assets/signing-option/bonus-gold2.webp",
  "tier-explanation-bg": CDN_URL + "/image-assets/signing-option/tier-explanation-bg.webp",

  // LOGIN FOLDER
  "facebook-button": CDN_URL + "/image-assets/signing-option/btn_facebook.png",
  "google-button": CDN_URL + "/image-assets/signing-option/btn_google.png",
  "text-login": CDN_URL + "/image-assets/signing-option/login_text.png",
  "btn-big": CDN_URL + "/image-assets/signing-option/btn-big.webp",
  "btn-small": CDN_URL + "/image-assets/signing-option/btn-small.webp",
  "btn-mask": CDN_URL + "/image-assets/signing-option/btn-mask.png",
  "btn-mask-disable": CDN_URL + "/image-assets/signing-option/btn-mask-disable.png",
  
  // FORGOT
  "btn-close-red": CDN_URL + "/image-assets/signing-option/btn-close-red.png",
  "text-check-email": CDN_URL + "/image-assets/signing-option/text-check-email.png",
  "text-forgot": CDN_URL + "/image-assets/signing-option/text-forgot.png",
  "text-reset-password": CDN_URL + "/image-assets/signing-option/text-reset-password.png",
  "text-success": CDN_URL + "/image-assets/signing-option/text-success.png",
  
  // SIGNUP
  "ic-mail": CDN_URL + "/image-assets/signing-option/ic-mail.png",
  "ic-circle-cross": CDN_URL + "/image-assets/signing-option/ic-circle-cross.png",
  "text-create-account": CDN_URL + "/image-assets/signing-option/text-create-account.png",
  "verify-success-check": CDN_URL + "/image-assets/signing-option/verify-success-check.png",

  // LOBBY
  "bg_popup": CDN_URL + "/image-assets/lobby/bg_popup.webp",
  "arrow_right": CDN_URL + "/image-assets/lobby/arrow_right.png",
  "feature_border": CDN_URL + "/image-assets/lobby/feature_border.webp",
  "lock_bronze": CDN_URL + "/image-assets/lobby/lock_bronze.png",
  "lock_silver": CDN_URL + "/image-assets/lobby/lock_silver.png",
  "lock_gold": CDN_URL + "/image-assets/lobby/lock_gold.png",
  "game_frame": CDN_URL + "/image-assets/lobby/game_frame.png",
  "ic_top_game": CDN_URL + "/image-assets/lobby/ic_top_game.png",
  "heart": CDN_URL + "/image-assets/lobby/heart.png",
  "heart-active": CDN_URL + "/image-assets/lobby/heart-active.png",
  "tier_bronze": CDN_URL + "/image-assets/lobby/tier_bronze.png",
  "tier_silver": CDN_URL + "/image-assets/lobby/tier_silver.png",
  "tier_gold": CDN_URL + "/image-assets/lobby/tier_gold.png",
  "btn-gray-big": CDN_URL + "/image-assets/lobby/btn-gray-big.png",
  "curtain": CDN_URL + "/image-assets/lobby/curtain.png",
  "side-bar": CDN_URL + "/image-assets/lobby/side-bar.png",
  "fb_share": CDN_URL + "/image-assets/lobby/fb_share.png",
  "ads-bg": CDN_URL + "/image-assets/lobby/ads-bg.png",
  "feature_frame": CDN_URL + "/image-assets/lobby/feature_frame.webp",
  "featured": CDN_URL + "/image-assets/lobby/featured.png",
  "btn_shop": CDN_URL + "/image-assets/lobby/btn_shop.webp",
  "btn-mission": CDN_URL + "/image-assets/lobby/btn-mission.webp",
  "btn_ball": CDN_URL + "/image-assets/lobby/btn_ball.webp",
  "btn_bonus": CDN_URL + "/image-assets/lobby/btn_bonus.webp",
  "btn_rank": CDN_URL + "/image-assets/lobby/btn_rank.webp",
  "btn_hourglass": CDN_URL + "/image-assets/lobby/btn_hourglass.png",
  "hourglass_frame": CDN_URL + "/image-assets/lobby/hourglass_frame.webp",
  "hud_bot": CDN_URL + "/image-assets/lobby/hud_bot.webp",
  "btn-green": CDN_URL + "/image-assets/lobby/btn-green.webp",
  "silver_bar": CDN_URL + "/image-assets/lobby/silver_bar.webp",
  "avatar_bg": CDN_URL + "/image-assets/lobby/avatar_bg.webp",
  "btn_switch_silver": CDN_URL + "/image-assets/lobby/btn_switch_silver.webp",
  "btn_switch_gold": CDN_URL + "/image-assets/lobby/btn_switch_gold.webp",
  "btn_home": CDN_URL + "/image-assets/lobby/btn_home.webp",
  "btn_mail": CDN_URL + "/image-assets/lobby/btn_mail.webp",
  "gold_bar": CDN_URL + "/image-assets/lobby/gold_bar.webp",
  "hud_top": CDN_URL + "/image-assets/lobby/hud_top.webp",
  "btn_settings": CDN_URL + "/image-assets/lobby/btn_settings.webp",
  "gold_dropdown": CDN_URL + "/image-assets/lobby/gold_dropdown.webp",
  "gwz_shop": CDN_URL + "/image-assets/lobby/gwz_shop.png",
  "logo_bitpace": CDN_URL + "/image-assets/lobby/logo_bitpace.png",
  "coming-soon": CDN_URL + "/image-assets/lobby/coming-soon.png",

  // BITPACE
  "bitpace_error": CDN_URL + "/image-assets/bitpace/bitpace_error.png",
  "bitpace_success": CDN_URL + "/image-assets/bitpace/bitpace_success.png",
  "apcopay-logo": CDN_URL + "/image-assets/bitpace/apcopay-logo.png",

  // AVATARS
  "Avatar_boy_1": CDN_URL + "/image-assets/avatars/Avatar_boy_1.webp",
  "Avatar_boy_2": CDN_URL + "/image-assets/avatars/Avatar_boy_2.webp",
  "Avatar_boy_3": CDN_URL + "/image-assets/avatars/Avatar_boy_3.webp",
  "Avatar_boy_4": CDN_URL + "/image-assets/avatars/Avatar_boy_4.webp",
  "Avatar_boy_5": CDN_URL + "/image-assets/avatars/Avatar_boy_5.webp",
  "avatar_frame": CDN_URL + "/image-assets/avatars/avatar_frame.webp",
  "Avatar_girl_1": CDN_URL + "/image-assets/avatars/Avatar_girl_1.webp",
  "Avatar_girl_2": CDN_URL + "/image-assets/avatars/Avatar_girl_2.webp",
  "Avatar_girl_3": CDN_URL + "/image-assets/avatars/Avatar_girl_3.webp",
  "Avatar_girl_4": CDN_URL + "/image-assets/avatars/Avatar_girl_4.webp",
  "Avatar_girl_5": CDN_URL + "/image-assets/avatars/Avatar_girl_5.webp",

  // PROFILE
  "tier_arrow1": CDN_URL + "/image-assets/profile/tier_arrow1.webp",
  "tier_arrow2": CDN_URL + "/image-assets/profile/tier_arrow2.webp",
  "tier_arrow3": CDN_URL + "/image-assets/profile/tier_arrow3.webp",
  "bg_player": CDN_URL + "/image-assets/profile/bg_player.webp",
  "tier_rewards": CDN_URL + "/image-assets/profile/tier_rewards.webp",
  "bg_edit_name": CDN_URL + "/image-assets/profile/bg_edit_name.png",
  "update_profile": CDN_URL + "/image-assets/profile/update_profile.png",
  "your_tier": CDN_URL + "/image-assets/profile/your_tier.webp",
  "profile_frame": CDN_URL + "/image-assets/profile/profile_frame.webp",
  "icon_edit": CDN_URL + "/image-assets/profile/icon_edit.png",
  "Player_level_Bronze": CDN_URL + "/image-assets/profile/Player_level_Bronze.webp",
  "Player_level_Cooper": CDN_URL + "/image-assets/profile/Player_level_Cooper.webp",
  "Player_level_Gold": CDN_URL + "/image-assets/profile/Player_level_Gold.webp",
  "Player_level_Silver": CDN_URL + "/image-assets/profile/Player_level_Silver.webp",
  "tier_frame": CDN_URL + "/image-assets/profile/tier_frame.webp",
  "star": CDN_URL + "/image-assets/profile/star.png",

  // THUMBNAILS
  "th_highroller": CDN_URL + "/image-assets/thumbnails/th_highroller.png",
  "th_hotdeals": CDN_URL + "/image-assets/thumbnails/th_hotdeals.png",
  "th_starterpack": CDN_URL + "/image-assets/thumbnails/th_starterpack.png",
  "th_tgif": CDN_URL + "/image-assets/thumbnails/th_tgif.png",
  "th_weeklybooster": CDN_URL + "/image-assets/thumbnails/th_weeklybooster.png",
  "th_whistle_warrior": CDN_URL + "/image-assets/thumbnails/th_whistle_warrior.png",


  // INBOX
  "bg_message": CDN_URL + "/image-assets/inbox/bg_message.png",
  "ic_fortune": CDN_URL + "/image-assets/inbox/ic_fortune.webp",
  "ic_congrats": CDN_URL + "/image-assets/inbox/ic_congrats.png",
  "ic_daily": CDN_URL + "/image-assets/inbox/ic_daily.png",
  "ic_elves": CDN_URL + "/image-assets/inbox/ic_elves.png",
  "ic_more_games": CDN_URL + "/image-assets/inbox/ic_more_games.png",
  "ic_play": CDN_URL + "/image-assets/inbox/ic_play.png",
  "ic_trophy_bronze": CDN_URL + "/image-assets/inbox/ic_trophy_bronze.png",
  "ic_trophy_silver": CDN_URL + "/image-assets/inbox/ic_trophy_silver.png",
  "ic_trophy_gold": CDN_URL + "/image-assets/inbox/ic_trophy_gold.png",
  "ic_bonus": CDN_URL + "/image-assets/inbox/ic_bonus.png",
  "ic_version": CDN_URL + "/image-assets/inbox/ic_version.png",
  "ic_candy": CDN_URL + "/image-assets/inbox/ic_candy.png",
  "inbox": CDN_URL + "/image-assets/inbox/inbox.png",
  "bg-inbox": CDN_URL + "/image-assets/inbox/bg-inbox.png",
  "inbox-item-bg": CDN_URL + "/image-assets/inbox/inbox-item-bg.png",
  "inbox-item-unread-bg": CDN_URL + "/image-assets/inbox/inbox-item-unread-bg.png",
  "inbox-close": CDN_URL + "/image-assets/inbox/inbox-close.png",
  "t-inbox-empty": CDN_URL + "/image-assets/inbox/t-inbox-empty.png",

  // GOLD SHOP
  "gold-coin-2": CDN_URL + "/image-assets/goldshop/gold-coin-2.png",
  "gold-palm": CDN_URL + "/image-assets/goldshop/gold-palm.png",
  "palm-tree": CDN_URL + "/image-assets/goldshop/palm-tree.png",
  "gold-silver": CDN_URL + "/image-assets/goldshop/gold-silver.png",
  "goldshop": CDN_URL + "/image-assets/goldshop/goldshop.png",
  "gs-shop": CDN_URL + "/image-assets/goldshop/gs-shop.png",
  "product-item": CDN_URL + "/image-assets/goldshop/product-item.png",
  "product-item-active": CDN_URL + "/image-assets/goldshop/product-item-active.png",
  "notes-insufficient": CDN_URL + "/image-assets/goldshop/notes-insufficient.png",
  "notes-success": CDN_URL + "/image-assets/goldshop/notes-success.png",
  "t-exchange-gold": CDN_URL + "/image-assets/goldshop/t-exchange-gold.png",
  "purchase-modal-bg": CDN_URL + "/image-assets/goldshop/purchase-modal-bg.png",
  "silver-package": CDN_URL + "/image-assets/goldshop/silver-package.png",
  "goldshop-background": CDN_URL + "/image-assets/goldshop/goldshop-background.png",
  "transparent-coin-silver-1": CDN_URL + "/image-assets/goldshop/transparent-coin-silver-1.png",
  "transparent-coin-silver-2": CDN_URL + "/image-assets/goldshop/transparent-coin-silver-2.png",
  "transparent-coin-silver-3": CDN_URL + "/image-assets/goldshop/transparent-coin-silver-3.png",
  "gold-coin": CDN_URL + "/image-assets/goldshop/gold-coin.png",
  "silver-coin": CDN_URL + "/image-assets/goldshop/silver-coin.png",
  "scroll-base": CDN_URL + "/image-assets/goldshop/scroll-base.png",
  "scroll-bar": CDN_URL + "/image-assets/goldshop/scroll-bar.png",
  "chest-1": CDN_URL + "/image-assets/goldshop/chest-1.png",
  "chest-2": CDN_URL + "/image-assets/goldshop/chest-2.png",
  "chest-3": CDN_URL + "/image-assets/goldshop/chest-3.png",
  "chest-4": CDN_URL + "/image-assets/goldshop/chest-4.png",
  "check": CDN_URL + "/image-assets/goldshop/check.png",
  "sword-master-package": CDN_URL + "/image-assets/goldshop/sword-master-package.png",

  // MISSION
  "100K": CDN_URL + "/image-assets/mission/100K.png",
  "fortune_vault": CDN_URL + "/image-assets/mission/fortune_vault.webp",
  "mission-background": CDN_URL + "/image-assets/mission/mission-background.png",
  "stacked-coin": CDN_URL + "/image-assets/mission/stacked-coin.png",
  "whistle": CDN_URL + "/image-assets/mission/whistle.png",
  "missions-button-play": CDN_URL + "/image-assets/mission/missions-button-play.png",
  "missions-button-collect": CDN_URL + "/image-assets/mission/missions-button-collect.png",
  "missions-button-collected": CDN_URL + "/image-assets/mission/missions-button-collected.png",

  // POPUP NOTICE
  "bg-notice": CDN_URL + "/image-assets/popup-notice/bg-notice.png",
  "mission_3keys": CDN_URL + "/image-assets/popup-notice/mission_3keys.png",
  "mission_completed": CDN_URL + "/image-assets/popup-notice/mission_completed.png",
  "mission_vault": CDN_URL + "/image-assets/popup-notice/mission_vault.webp",
  "mission-shine-bg": CDN_URL + "/image-assets/popup-notice/mission-shine-bg.png",
  "mission-text": CDN_URL + "/image-assets/popup-notice/mission-text.png",
  "note_1_key": CDN_URL + "/image-assets/popup-notice/note_1_key.png",
  "note_3_key": CDN_URL + "/image-assets/popup-notice/note_3_key.png",
  "task_completed": CDN_URL + "/image-assets/popup-notice/task_completed.png",
  "exclamation": CDN_URL + "/image-assets/popup-notice/exclamation.png",
  "notice": CDN_URL + "/image-assets/popup-notice/notice.png",
  "insufficient-coin": CDN_URL + "/image-assets/popup-notice/insufficient-coin.png",
  "referee_2": CDN_URL + "/image-assets/popup-notice/referee_2.png",
  "successful-purchase": CDN_URL + "/image-assets/popup-notice/successful-purchase.png",
  "notice-check": CDN_URL + "/image-assets/popup-notice/notice-check.png",
  "t-gold-echange": CDN_URL + "/image-assets/popup-notice/t-gold-echange.png",
  "bg-notice-2": CDN_URL + "/image-assets/popup-notice/bg-notice-2.png",
  
  // LEADERBOARD
  "weekly_tournament": CDN_URL + "/image-assets/leaderboard/weekly_tournament.png",
  "l_your_rank": CDN_URL + "/image-assets/leaderboard/l_your_rank.png",
  "bg_lboard": CDN_URL + "/image-assets/leaderboard/bg_lboard.png",
  "trophy2": CDN_URL + "/image-assets/leaderboard/trophy2.png",
  "trophy3": CDN_URL + "/image-assets/leaderboard/trophy3.png",
  "trophies": CDN_URL + "/image-assets/leaderboard/trophies.png",
  "weekly_tournament_text": CDN_URL + "/image-assets/leaderboard/weekly_tournament_text.png",
  
  // GAMEVIEW
  "play_gold": CDN_URL + "/image-assets/gameview/play_gold.png",
  "play_silver": CDN_URL + "/image-assets/gameview/play_silver.png",
  "text-select-coin": CDN_URL + "/image-assets/gameview/text-select-coin.png",

};

// load splash screen resources first
export const SplashAssets = [
  images["bg-splash"],
  images["elements"],
  images["referee"],
  images["bg-progress"],
];

// load portrait animation
export const PortraitAssets = [
  LandscapeAssets["img_0"],
  LandscapeAssets["img_1"],
  LandscapeAssets["img_2"],
  LandscapeAssets["img_3"],
];

// image preloading
export const ImageAssets = Object.keys(images).map((key: string) => images[key as keyof typeof images]);

export default images;
