import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from "@env";
import Portrait from "components/portrait";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "./components/modals/error-sideway";
import SuccessTop from "./components/modals/success-top";
import useSoundService from "./ducks/hooks/useSoundService";

const gtmParams = {
  id: GTM_ID,
  environment: {
    gtm_auth: GTM_AUTH,
    gtm_preview: GTM_PREVIEW,
  },
};

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  handleClick: () => void;
  onErrorMessage: (value: string) => void;
  onSuccessMessage: (value: string) => void;
}

export const AuthContext = React.createContext<ContextValue>(
  {} as ContextValue
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { bgsoundPlay, clickSoundToggle } = useSoundService();

  const handleClick = useCallback(async() => {
    clickSoundToggle();
  }, []);

  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(setSuccess, [success]);

  useEffect(() => {
    bgsoundPlay();
  }, []);

  return (
    <AuthContext.Provider value={{ handleClick, onErrorMessage, onSuccessMessage }}>
      <GTMProvider state={gtmParams}>
        {children}
      </GTMProvider>
      <Portrait />
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
      <SuccessTop
        message={success}
        visible={success !== null}
        onClose={() => setSuccess(null)}
      />
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
